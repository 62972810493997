import React, { useState } from "react";

import {
  Box,
  Heading,
} from "@chakra-ui/react";
import { ContactForm, CustomBox } from "../components";

export default function ContactView() {
  const [isMessageSent, setIsMessageSent] = useState(false);

  function onFormValidation() {
    setIsMessageSent(!isMessageSent);
  }

  return (
    <CustomBox>
      <Box h='7.5%' w='100%'/>
      <CustomBox h="17.5%">
        <Heading>Formulaire de contact</Heading>
      </CustomBox>
      <CustomBox h='75%'>
        { isMessageSent
        ? <Heading>Votre message a bien été envoyé</Heading>
        : <ContactForm
            onFormValidation={onFormValidation}
          />
        }
      </CustomBox>
    </CustomBox>
  );
};