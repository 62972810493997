import React from "react";

import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  Image,
  Heading,
  Text,
} from "@chakra-ui/react";
import { useWindowSize } from "react-use";

import CustomBox from "./CustomBox";

export default function ExhibitionDetailsGrid(props) {
  const { width } = useWindowSize();
  const colSpan = width <= 767 ? 6 : 3;
  const rowSpan = width <= 767 ? 6 : 3;

  return (
    <Flex  h="100%" w="90%" alignItems={"center"} justifyContent={"center"}>
      <Grid 
        backgroundColor='transparent'
        key={props.key}
        templateColumns="repeat(6, 1fr)"
        templateRows="repeat(6, 1fr)"
        gap={5}
      >
        <GridItem colSpan={colSpan} rowSpan={5} backgroundColor='transparent'>
          <Box>
            <Image
              src={props.item.image}
              alt="Image description"
              boxSize="80%"
              objectFit="contain"
              borderRadius="10"
            />
          </Box>

        </GridItem>
        <GridItem colSpan={colSpan} rowSpan={2} backgroundColor='transparent'>
          <CustomBox alignItems='left'>
            <Heading fontSize={width <= 767 ? 24: 32}>{props.item.title}</Heading>
          </CustomBox>
        </GridItem>
        {/* <GridItem colSpan={2} rowSpan={1} backgroundColor='transparent'>
          <Box>
            <Text>{props.item.description}</Text>
          </Box>
        </GridItem> */}
        <GridItem
          colSpan={colSpan/3}
          rowSpan={props.item.openingHours ? 2 : 1}
          textAlign='left'
          paddingRight='25%'
          backgroundColor='transparent'
        >
          <Text fontWeight="bold">Date</Text>
        </GridItem>
        <GridItem
          colSpan={colSpan*2/3}
          rowSpan={props.item.openingHours ? 2 : 1}
          textAlign='left'
          backgroundColor='transparent'
        >
          <Box>
            <Text paddingBottom='2.5%'>{props.item.date}</Text>
            {
              props.item.openingHours
              ? props.item.openingHours.map((element, index) =>
                <Text key={index}>{element}</Text>
              )
              : null
            }
          </Box>
        </GridItem>
        <GridItem
          colSpan={colSpan/3}
          rowSpan={1}
          textAlign='left'
          paddingRight='25%'
          backgroundColor='transparent'
        >
          <Text fontWeight="bold">Lieu</Text>
        </GridItem>
        <GridItem
          colSpan={colSpan*2/3}
          rowSpan={1}
          textAlign='left'
          backgroundColor='transparent'
        >
          <Box>
            <Text paddingBottom='2.5%'>{props.item.location}</Text>
            <Text>{props.item.address}</Text>
          </Box>
        </GridItem>
        {/* <GridItem
          colSpan={1}
          rowSpan={1}
          textAlign='left'
          paddingRight='25%'
          backgroundColor='transparent'
        >
          <Text>Adresse</Text>
        </GridItem>
        <GridItem
          colSpan={2}
          rowSpan={1}
          textAlign='left'
          backgroundColor='transparent'
        >
          <Box>
            <Text>{props.item.address}</Text>
          </Box>
        </GridItem> */}
        <GridItem colSpan={6} rowSpan={1}/>
      </Grid>
    </Flex>
  );
};