function importAll(r, listOfImages) {
  let images = {};
  r.keys().forEach((item, index) => {
    // console.log("test", item, index);
    listOfImages.push(images["image"] = item.replace('./', '')); });
  // return images
 }
 
const listOfImages = [];
importAll(require.context('../../public/images', false, /\.(jsx|jpe?g|HEIC)$/), listOfImages);

export default listOfImages;