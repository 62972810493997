import {
  extendTheme,
  withDefaultColorScheme
} from "@chakra-ui/react";

const theme = extendTheme(
  {
    colors: {
      primary: {
        main: "rgba(0, 0, 40, 0.9)",
        text: "rgba(255,255,255,0.9)"
      },
    },
    components: {
      Box: {
        defaultProps: {
          backgroundColor: "green",
          colorScheme: 'green',
        },
      },
    },
  },
  withDefaultColorScheme({
    colorScheme: "primary.main"
  }),
)

export default theme;