import React, { useState, useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { CSSTransition } from "react-transition-group";
import "./styles.css";

const AnimatedChevron = () => {
  const [isVisible, setIsVisible] = useState(false);
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsVisible(prevState => !prevState);
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <CSSTransition
      in={isVisible}
      timeout={2000}
      classNames="fade"
      unmountOnExit
      onExited={() => setIsVisible(false)}
    >
      <Box display='flex' flexDirection='column'>
        <ChevronDownIcon boxSize={10} />
        <ChevronDownIcon boxSize={10} />
      </Box>
    </CSSTransition>
  );
};

export default AnimatedChevron;
