import React from "react";

import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  VStack,
  Text, 
  propNames} from "@chakra-ui/react";

import exhibitionConfig from "../constants/exhibitionConfig";
import { AnimatedChevron, CustomBox, ExhibitionDetailsGrid } from "../components";

export default function ExhibitionView() {
  return (
    <Box h="100%">
      <Box h='7.5%' w='100%' bg='transparent' />
      <Box h="92.5%" alignItems={"center"} justifyContent={"center"} overflowX={"auto"}>
        <VStack>
          <Box paddingTop='2.5%' paddingBottom='5%'>
            <Heading>
              Prochaine exposition
            </Heading>
          </Box>
          {exhibitionConfig["lastExhibition"].map((item, index) => 
            <ExhibitionDetailsGrid
              key={index}
              item={item}
            />
          )}
          <Box
            h='11%'
            w='5%'
            position='absolute'
            left='1%'
            bottom='1%'
          >
            <CustomBox>
              <AnimatedChevron />
            </CustomBox>
          </Box>
          <Box paddingTop='5%' paddingBottom='5%'>
            <Heading>
              Expositions passées
            </Heading>
          </Box>
          {exhibitionConfig["otherExhibitions"].map((item, index) => 
            <ExhibitionDetailsGrid
              key={index}
              item={item}
            />
          )}
        </VStack>
      </Box>
    </Box>
  );
};