const aboutMeDescription = [
  {
    id: 1,
    content: "Depuis son adolescence, Henri Giraud a toujours dessiné et pratiqué l'aquarelle. Cet intérêt \
    existait déjà au moment de commencer ses études supérieures et il se traduisit par une hésitation entre la \
    préparation des Beaux Arts (section architecture) et celle des Hautes Etudes Commerciales. Le hasard \
    décida en faveur de la seconde mais la passion ne s'était pas éteinte pour autant car il suivit des \
    cours de dessin chaque soir pendant deux années dans un atelier à Paris. Ensuite, il se lança dans une \
    carrière dans le domaine des Fusions et Aquisitions, où ses fonctions l'amenèrent à beaucoup voyager \
    dans tous les continents. Au cours de ses fréquentes pérégrinations, Henri Giraud emportait toujours \
    avec lui une boite de couleurs et du papier d'aquarelle."
  },
  {
    id: 2,
    content: "En effet, une des caractéristiques de ce mode d'expression est qu'il ne nécessite pas de matériel \
    lourd et encombrant et donc qu'il peut être pratiqué partout où l'oeil est accroché soit par la beauté d'un \
    paysage, soit par un point de vue intéressant."
  },
  {
    id: 3,
    content: "Le véritable atelier d'Henri Giraud est l'extérieur : toutes ses oeuvres sont faites dehors ; il en \
      résulte une certaine dépendance des conditions climatiques et surtout de la lumière ; il lui faut ainsi \
      souvent revenir au lieu qui a été repéré pour y être à une heure où l'éclairage, c'est à dire le soleil \
      et les ombres, est favorable à l'inspiration. Une autre particularité de l'aquarelle est la nécessité \
      de travailler vite pour traduire une combinaison fugitive de couleurs ou donner une impression de \
      lumière qui, par définition, se modifie rapidement."
  },
  {
    id: 4,
    content: "La conjonction de la nécessité d'être rapide avec une disponibilité de temps limitée, du fait d'une \
      activité professionnelle (Directeur Fusions Acquisitions) très intense, se révéla paradoxalement plus \
      comme un moteur de stimulation que comme un frein car elle permettait, dans sa peinture, d'éviter \
      l'écueil de la pesanteur en obligeant à créer une impression de spontanéité et d'instantanéité. \
      En effet, il n'est pas possible de corriger une aquarelle et ceci contraint soit à réussir soit à \
      détruire, c'est à dire déchirer."
  },
  {
    id: 5,
    content: "C'est en jouant avec ces impératifs tout au long de sa vie professionnelle, qu'Henri Giraud a su \
      utiliser la dualité de ses inclinations, en utilisant tout moment de liberté, pendant des voyages \
      ou pendant ses vacances, pour paryiquer son passe-temps favori. Il en résulta une production assez \
      abondante qui donna lieu déjà à plusieurs expositions individuelles : trois expositions aux Etats \
      Unis (deux à Larchmont (état de New York) et une à New York City, deux expositions au Luxembourg et \
      sept expositions en France (à Paris))."
  }
];

export default aboutMeDescription;