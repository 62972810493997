const contactFormConfig = [
  {
    formId: "email",
    formLabel: "Email address",
    formInputType: "email",
    formErrorMessage: "Email is required",
    formHelperMessage: "We'll never share your email"
  },
  {
  formId: "subject",
  formLabel: "Titre",
  formInputType: "text",
  formErrorMessage: "L'object est requis",
  formHelperMessage: ""
  },
  {
    formId: "message",
    formLabel: "Message",
    formInputType: "text",
    formErrorMessage: "Message is required",
    formHelperMessage: ""
  }
]

export default contactFormConfig;