import React from "react";

import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";

import AppHeader from "./components/AppHeader.jsx";
import RouterOutlet from "./router/RouterOutlet.jsx";
import theme from "./globalStyles.js";
import MainBox from "./components/MainBox.jsx";
import Footer from "./components/Footer.jsx";

export default function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <MainBox>
          <AppHeader />
          <RouterOutlet />
          {/* <Footer /> */}
        </MainBox>
      </BrowserRouter>
    </ChakraProvider>
  );
};