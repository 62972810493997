import React from "react";
import { Route, Routes } from "react-router-dom";

import Gallery from "../pages/Gallery";
import ExhibitionView from "../pages/ExhibitionView";
import ContactView from "../pages/ContactView";
import AboutMe from "../pages/AboutMe";


export default function RouterOutlet() {
  return (
    <Routes>
      <Route path="/*" element={<Gallery />} />
      <Route path="/exhibition" element={<ExhibitionView />} />
      <Route path="/contact" element={<ContactView />} />
      <Route path="/about-me" element={<AboutMe />} />
    </Routes>
  )
}