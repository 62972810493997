import React from "react";

import { Link, useNavigate } from "react-router-dom";
import { Box, Button, Flex, Heading, HStack, Menu, MenuButton, MenuList, MenuItem, useColorMode, useColorModeValue , useDisclosure } from "@chakra-ui/react";

import { CustomBox } from "./index";
import exploreRouterMenu from "../constants/menuConfig";

export default function AppHeader() {
  const {isOpen, onOpen, onClose } = useDisclosure();
  // const { colorMode, toggleColorMode } = useColorMode();
  const navigate = useNavigate();

  const navigateToDiscover = (type) => {
    navigate(`/${type}`);
    if (isOpen) onClose();
  };

  return (
    <Box
      height="7.5%"
      width="100%"
      position="absolute"
      px={5}
      top={0}
      transition="box-shadow 0.2s"
      boxShadow={"lg"}
      zIndex={2}
      bg="rgba(0, 0, 40, 0.5)"
    >
      <Flex h="100%" alignItems={"center"} justifyContent={"space-between"}>
        <HStack alignItems={"center"}>
          <Heading
            as={Link}
            to={"/"}
            fontWeight={"bold"}
            size={"lg"}
            color="primary.text"
          >
            Henri Giraud
          </Heading>
        </HStack>
        <HStack alignItems={"center"} spacing={5}>
          <Box display={{base: "none", md: "block"}}>
            {exploreRouterMenu.map((menu) =>
            <Menu key={menu.sectionId}>
              <MenuButton
                as={Button}
                onClick={() => navigateToDiscover(menu.path)}
                size={"md"}
                fontWeight="bold"
                color="primary.text"
              >
                {menu.sectionLabel}
              </MenuButton>
            </Menu>)}
          </Box>
        </HStack>
                
      </Flex>
    </Box>
  );
}