const exhibitionConfig = {


    lastExhibition: [
      {
        title: "Images du 5e, paysages d'Europe",
        image: "affiche-expo-2023.png",
        date: "15 au 24 Février 2023",
        openingHours: [
          "Lundi-Mercredi: 9h-18h", 
          "Jeudi: 9h-19h",
          "Vendredi-Samedi: 9h-18h"
        ],
        location: "Mairie du Vème arrondissement - Salle René Capitant",
        address: "21 Place du Panthéon, Paris 5e",
        access: "Acces 1",
        description: "Ceci est la description de ma première exposition"
      },
    ],


    otherExhibitions: [
      {
        title: "De par le monde...",
        image: `images/IMG_6984~photo.jpg`,
        date: "14 au 20 Novembre 2013",
        location: "Mairie du 7e arrondissement",
        address: " 116 Rue de Grenelle, Paris 7e",
        access: "Métro: Varenne, Solférino - Bus: 69",
        description: "Description"
      },
      {
        title: "Toits, émoits...",
        image: `images/IMG_6990~photo.jpg`,
        date: "13 au 18 Novembre 2009",
        location: "Mairie du 7ème arrondissement",
        address: "116 rue de Grenelle, Paris 7e",
        access: "Métro: Varenne, Solférino - Bus: 69",
        description: "Ceci est la description de ma première exposition"
      },
    ]
};

export default exhibitionConfig;