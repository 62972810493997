import React from "react";

import {
  Box,
  Heading,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";

import aboutMeDescription from "../constants/aboutMeDescription";
import { CustomBox } from "../components";


export default function AboutMe() {

  return (
    <CustomBox h='100%' w='100%'>
      <Box h='7.5%' w='100%' />
      <CustomBox h="17.5%" bg='green'>
        <Heading>A propos du peintre</Heading>
      </CustomBox>
      <Box h='45%'>
        <Image
          src={`photo-artiste.jpg`}
          alt="Image description"
          boxSize="100%"
          objectFit="contain"
          borderRadius="10"
        />
      </Box>
      <VStack h='30%' w='70%'>
        {aboutMeDescription.map((item, index) => 
          <Text paddingTop='2.5%' textAlign='justify' lineHeight='8'>
            {item.content}
          </Text>
        )}
        <Box minHeight='20%' w='100%' />
      </VStack>
    </CustomBox>
  );
};