import React from "react";

import { Flex } from "@chakra-ui/react";

export default function MainBox(props) {
  return (
    <Flex
      height="100vh"
      width="100vw"
      display="flex"
      flexDirection="column"
      backgroundColor="primary.main"
      textColor="primary.text"
    >
      {props.children}
    </Flex>
  );
};