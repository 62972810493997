import React from "react";

import {
  Box,
  Center,
  Grid,
  GridItem,
  Image,
  Link,
  Text
} from "@chakra-ui/react";
import { useWindowSize } from "react-use";

import { AppHeader, AnimatedChevron, CustomBox, } from "../components";
import listOfImages from "../constants/listImages";

export default function Gallery() {
  const { width } = useWindowSize();
  const colSpan = width <= 767 ? 4 : 2;

  return (
    <CustomBox overflowX={"auto"}>
      {/* <Heading 
        color="white"
        marginTop="2%"
        marginBottom="2%"
      >
        Gallerie
      </Heading> */}
      <Grid
        h="100%" w="100%"
        scrollMarginTop="50"
        paddingTop="2.5%"
        templateColumns="repeat(4, 1fr)"
        gap={10}
        overflowX="auto"
        css={{
          WebkitOverflowScrolling: "touch",
          msOverflowStyle: "-ms-autohiding-scrollbar"
        }}
        sx={{
          scrollbarWidth: 'none',
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Box h='7.5%' w='100%' />
        <GridItem colSpan={4}>
          <Center h="87.5vh" bg="transparent" justifyContent="start">
            <Image
              src={`images/IMG_6996~photo.jpg`}
              alt="Image description"
              boxSize="100%"
              objectFit="contain"
              borderRadius="10"
            />
          </Center>
          <Box
              h='11%'
              w='5%'
              // bg='green'
              position='sticky'
              right='1%'
              bottom='1%'
          >
            <CustomBox>
              <AnimatedChevron />
            </CustomBox>
          </Box>
        <GridItem colSpan={4}>
          <Box w='100%' marginTop='2%'>
            <Text textAlign={'center'} fontSize='18'>
              La prochaine exposition aura lieu du 15 au 24 Février 2023, à la Mairie du 5ème. Plus d'informations:
              <Link
                href='https://mairie05.paris.fr/pages/exposition-d-aquarelles-henri-giraud-15-au-24-fevrier-2023-23061' 
                isExternal
                marginLeft='0.5%'
                fontWeight={'bold'}
              >
                sur ce lien
              </Link>
            </Text>
            <Box minHeight='10' />
            <Text textAlign={'center'} fontSize='18'>
              En attendant, voici un aperçu de quelques aquarelles qui y seront exposées:
            </Text>
          </Box>
        </GridItem>
        </GridItem>
        {listOfImages.map((item, index) => 
            <GridItem colSpan={colSpan}>
            {/* <GridItem colSpan={Math.floor(1 + (Math.random()) * 1.2)}> */}
            {/* <Text key={index}>Index: {index}</Text> */}
            <Image
              key={index}
              src={`images/${item}`}
              alt="Image description"
              boxSize="100%"
              objectFit="contain"
              borderRadius="10"
              bg="transparent"
            />
          </ GridItem>
        )}
        <GridItem colSpan={4}>
          <Box minHeight='10%'/>
        </GridItem>
      </Grid>
    </CustomBox>
  );
};