import React from "react";

import { Flex } from "@chakra-ui/react";

export default function CustomBox(props) {
  return (
    <Flex
      display="flex"
      flexDirection="column"
      justifyContent={props.justifyContent ? props.justifyContent : "center"}
      alignItems={props.alignItems ? props.alignItems : "center" }
      backgroundColor={props.backgroundColor}
      height={props.h ? props.h : "100%"}
      width={props.w ? props.w : "100%"}
      overflowX={"auto"}
      props
    >
      {props.children}
    </Flex>
  );
};