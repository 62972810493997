import React from "react";

import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  VStack
} from "@chakra-ui/react";

import contactFormConfig from "../constants/contactFormConfig";
import { CustomBox } from "../components";

export default function ContactForm(props) {

  function onValidation() {
    props.onFormValidation();
  }

  return (
    <VStack>
      {contactFormConfig.map((form) => 
        <FormControl isRequired>
          <FormLabel>{form.formLabel}</FormLabel>
          <Input type={form.formInputType} />
          <FormErrorMessage>{form.formErrorMessage}</FormErrorMessage>
          <FormHelperText>{form.formHelperMessage}</FormHelperText>
        </FormControl>
      )}
      <CustomBox>
        <Button onClick={() => onValidation()}>Envoyer</Button>
      </CustomBox>
    </VStack>
  );
}