const exploreRouterMenu = [
  {
    sectionId: "gallery",
    sectionLabel: "Galerie",
    path: "gallery"
  },
  {
    sectionId: "exhibitions",
    sectionLabel: "Expositions",
    path: "exhibition",
    sectionItems: [
      {
        path: "last-exhibition",
        label: "Dernière exposition",
      },
      {
        path: "next-exhibition",
        label: "A venir"
      },
    ],
  },
  {
    sectionId: "about-me",
    sectionLabel: "A propos",
    path: "about-me"
  },
  {
    sectionId: "contact",
    sectionLabel: "Contact",
    path: "contact"
  }
]

export default exploreRouterMenu;